import { gql } from '@apollo/client';
import {
  USER,
  ROLE,
  REGION,
  ROLE_PERMISSION,
  SCHOOL,
  COUPON,
  CATEGORY,
  COMPETITION,
  EVENT,
  REGISTRATION_OPTION,
  EVENT_COMPETITION,
  REGIONSUSER,
  SCHOOL_USER,
  COALITION,
  EVENTSUSER,
  MEMBER,
  LINE_ITEM,
  EVENT_USER,
  ORDER,
  FINALIZE_TEAM,
} from './Types';

export const SIGN_IN = gql`
  mutation login
  ($email: String!, $password: String!) {
    login(input: {
      email: $email,
      password: $password
    }) {
    token
    user {
      ${USER}
      fetchRole {
        ${ROLE}
        permissions {
          id
          title
          displayName
        }
      }
      fetchRegionsUsers {
        ${REGIONSUSER}
        fetchRole {
          ${ROLE}
          permissions {
            id
            title
            displayName
          }
        }
      }
    }
  }
}
`;

export const SIGN_UP = gql`
  mutation($email: String!, $password: String!, $passwordConfirmation: String!, $firstname: String!, $lastname: String!, $tenantId: Int!, $roleId: Int, $regionId: Int) {
    signUp(input: {
      email: $email,
      tenantId: $tenantId,
      firstname: $firstname,
      lastname: $lastname
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      roleId: $roleId,
      regionId: $regionId
    }) {
    token
    user {
      ${USER}
    }
    result
    active
  }
}
`;

export const NEW_PASSWORD = gql`
  mutation forgotpassword($email: String!) {
    forgotPassword(input:{
      email: $email
    }) {
    result
    active
  }
}
`;

export const EDIT_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(input:{
      token: $token,
      password: $password
    }) {
    user{
      ${USER}
    }
  }
}
`;

export const CREATE_ROLE = gql`
  mutation createRole
  ($title: String!, $termsAndConditions: String!, $tenantId: Int!, $level: Int!, $allowInvitation: Boolean) {
    createRole(input: {
      title: $title,
      termsAndConditions: $termsAndConditions,
      tenantId: $tenantId,
      level: $level,
      allowInvitation: $allowInvitation,
    }) {
    role {
       ${ROLE}
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole
  ($id: ID!, $termsAndConditions: String!, $level: Int!, $allowInvitation: Boolean) {
    updateRole(input: {
      id: $id,
      termsAndConditions: $termsAndConditions,
      level: $level,
      allowInvitation: $allowInvitation,
    }) {
      role {
        ${ROLE}
      }
    }
  }
`;

export const CREATE_REGION = gql`
  mutation createRegion
  ($name: String!, $stripeAccount: String, $currency: String!, $websiteUrl: String, $facebookUrl: String, $twitterUrl: String, $instagramUrl: String, $email: String, $tenantId: Int!, $tier: Int!, $userIds: [Int!]!, $timezone: String ) {
    createRegion(input: {
      name: $name,
      stripeAccount: $stripeAccount,
      currency: $currency,
      websiteUrl: $websiteUrl,
      facebookUrl: $facebookUrl,
      twitterUrl: $twitterUrl,
      instagramUrl: $instagramUrl,
      email: $email,
      tier: $tier,
      timezone: $timezone,
      tenantId: $tenantId,
      userIds: $userIds,
    }) {
      region {
        ${REGION}
      }
      message
    }
  }
`;

export const UPDATE_ROLE_PERMISSIONS = gql`
  mutation updateRolePermission
  ($rolePermissions: [RolePermissionInput!]!) {
    updateRolePermission(input:{
      rolePermissions: $rolePermissions,
    }) {
      rolePermissions {
        ${ROLE_PERMISSION}
      }
    }
  }
`;

export const UPDATE_REGION = gql`
  mutation updateRegion
  ($id: ID!, $name: String!, $stripeAccount: String, $currency: String!, $websiteUrl: String, $facebookUrl: String, $twitterUrl: String, $instagramUrl: String, $tier: Int!, $email: String , $userIds: [Int!], $timezone: String) {
    updateRegion(input: {
      id: $id,
      name: $name,
      stripeAccount: $stripeAccount,
      currency: $currency,
      tier: $tier,
      websiteUrl: $websiteUrl,
      facebookUrl: $facebookUrl,
      twitterUrl: $twitterUrl,
      instagramUrl: $instagramUrl,
      email: $email,
      userIds: $userIds,
      timezone: $timezone,
    }) {
      region {
        ${REGION}
      }
      message
    }
  }
`;

export const DELETE_ADMIN_FROM_REGION = gql`
  mutation deleteRegionsUser
  ($id: Int!){
    deleteRegionsUser(input: {
      id: $id
    }){
      region {
        ${REGION}
      }
    }
  }
`;


export const CREATE_SCHOOL = gql`
  mutation createSchool
  ($regionIds: [Int!]!, $name: String!, $address1: String!, $address2: String, $stateCode: String!, $postalCode: String!, $tenantId: Int!, $country: String!, $website: String!, $phone: String!, $denyComment: String!, $city: String!, $status: Int!) {
    createSchool(input: {
      regionIds: $regionIds,
      name: $name,
      address1: $address1,
      address2: $address2,
      stateCode: $stateCode,
      postalCode: $postalCode,
      tenantId: $tenantId,
      country: $country,
      website: $website,
      phone: $phone,
      city: $city,
      denyComment: $denyComment,
      status: $status,
    }) {
    school{
      ${SCHOOL}
      }
      message
    }
  }
`;

export const CREATE_COUPON = gql`
  mutation createCoupon
  ($code: String!, $value: Int!, $expirationDate: String!, $valuePercentage: Boolean!, $maxCodeUsage: Int!, $roleId: Int!, $eventId: Int!, $tenantId: Int!) {
    createCoupon(input: {
      code: $code,
      maxCodeUsage: $maxCodeUsage,
      expirationDate: $expirationDate,
      value: $value,
      valuePercentage: $valuePercentage,
      roleId: $roleId,
      tenantId: $tenantId
      eventId: $eventId
    }) {
      coupon {
        ${COUPON}
      }
    }
  }
`;

export const DELETE_REGION_FROM_SCHOOL = gql`
  mutation deleteSchoolRegion
  ($schoolId: Int!, $regionId: Int!){
    deleteSchoolRegion(input: {
      schoolId: $schoolId
      regionId: $regionId
    }){
    school{
      ${SCHOOL}
      }
    }
  }
`;

export const APPROVE_DENY_SCHOOL = gql`
  mutation approveDenySchool
  ($id: ID!, $status: Int!, $denyComment: String!){
    approveDenySchool(input: {
      id: $id
      status: $status
      denyComment: $denyComment
    }){
    school{
      ${SCHOOL}
      }
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation updateCoupon
  ($id: ID!, $code: String!, $expirationDate: String!, $value: Int!, $valuePercentage: Boolean!, $maxCodeUsage: Int, $roleId: Int, $eventId: Int) {
    updateCoupon(input: {
      id: $id,
      code: $code,
      maxCodeUsage: $maxCodeUsage,
      expirationDate: $expirationDate,
      value: $value,
      valuePercentage: $valuePercentage,
      roleId: $roleId,
      eventId: $eventId
    }) {
      coupon {
        ${COUPON}
      }
    }
  }
`;

export const EXPIRE_COUPON = gql`
  mutation cancelCoupon
  ($id: ID!) {
    cancelCoupon(input: {
      id: $id,
    }) {
      message
    }
  }
`;


export const UPDATE_SCHOOL = gql`
  mutation updateSchool
  ($id: ID!, $regionIds: [Int!]!, $name: String!, $address1: String!, $address2: String, $stateCode: String!, $postalCode: String!, $country: String!, $website: String!, $phone: String!, $denyComment: String!, $city: String!, $status: Int!) {
    updateSchool(input: {
      id: $id
      regionIds: $regionIds,
      name: $name,
      address1: $address1,
      address2: $address2,
      stateCode: $stateCode,
      postalCode: $postalCode,
      country: $country,
      website: $website,
      phone: $phone,
      city: $city,
      denyComment: $denyComment,
      status: $status,
    }) {
    school{
      ${SCHOOL}
      }
    }
  }
`;

export const REASSIGN_STUDENT = gql`
  mutation reassignStudent
  ($schoolId: Int!, $userIds: [Int!]!) {
    reassignStudent(input: {
      schoolId: $schoolId
      userIds: $userIds,
    }) {
    schoolsUsers{
      ${SCHOOL_USER}
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory
  ($title: String!, $tenantId: Int!) {
    createCategory(input: {
      title: $title,
      tenantId: $tenantId
    }) {
    category {
       ${CATEGORY}
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory
  ($id: ID!, $title: String!) {
    updateCategory(input: {
      title: $title,
      id: $id
    }) {
    category {
       ${CATEGORY}
      }
    }
  }
`;

export const CREATE_COMPETITION = gql`
  mutation createCompetition
  ($title: String!, $tenantId: Int!, $categoryId: Int!, $matchup: Boolean!, $group: Boolean!, $genderEnable: Boolean!, $onlineSubmission: Boolean!, $minTeam: Int!, $maxTeam: Int!, $maxSubmission: Int!, $level: Int!, $description: String!, $requiresAdditionalCompetition: Boolean!, $ballotId: Int! ) {
    createCompetition(input: {
      title: $title,
      tenantId: $tenantId
      categoryId: $categoryId
      matchup: $matchup
      group: $group
      genderEnable: $genderEnable
      onlineSubmission: $onlineSubmission
      minTeam: $minTeam
      maxTeam: $maxTeam
      maxSubmission: $maxSubmission
      level: $level
      description: $description
      requiresAdditionalCompetition: $requiresAdditionalCompetition
      ballotId: $ballotId
    }) {
    competition {
       ${COMPETITION}
      }
    }
  }
`;

export const UPDATE_COMPETITION = gql`
  mutation updateCompetition
  ($title: String!, $id: ID!, $matchup: Boolean!, $group: Boolean!, $genderEnable: Boolean!, $onlineSubmission: Boolean!, $minTeam: Int!, $maxTeam: Int!, $maxSubmission: Int!, $level: Int!, $description: String!, $requiresAdditionalCompetition: Boolean!, $ballotId: Int! ) {
    updateCompetition(input: {
      title: $title,
      id: $id
      matchup: $matchup
      group: $group
      genderEnable: $genderEnable
      onlineSubmission: $onlineSubmission
      minTeam: $minTeam
      maxTeam: $maxTeam
      maxSubmission: $maxSubmission
      level: $level
      description: $description
      requiresAdditionalCompetition: $requiresAdditionalCompetition
      ballotId: $ballotId
    }) {
    competition {
      id
      status
      title
      tenantId
      categoryId
      matchup
      maxTeam
      minTeam
      maxSubmission
      onlineSubmission
      requiresAdditionalCompetition
      group
      genderEnable
      level
      description
      fetchCategory{
        id
        title
        tenantId
      }
      fetchBallot {
        id
      }
      }
    }
  }
`;

export const INVITE_USER = gql`

  mutation($firstname: String, $lastname: String, $email: String!, $roleId: Int, $invitableId: Int!, $invitableType: String!, $registrationOptionId: Int) {
    inviteUser(input: {
      email: $email,
      roleId: $roleId,
      invitableId: $invitableId,
      firstname: $firstname,
      lastname: $lastname,
      invitableType: $invitableType,
      registrationOptionId: $registrationOptionId,
    }) {
      result
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent
  ($title: String!, $tenantId: Int!, $fee: Float!, $regionId: Int!, $location: String!, $financialAidFormLink: String!, $discordLink: String!, $submissionDeadline: String!, $startDate: String!, $endDate: String!, $label: String!, $judgeApplicationLink: String!, $sponsorApplicationLink: String!, $volunteerApplicationLink: String!, $termsAndConditions: String!, $attachment: Upload!) {
    createEvent(input: {
      title: $title,
      label: $label,
      startDate: $startDate,
      endDate: $endDate,
      submissionDeadline: $submissionDeadline,
      location: $location,
      financialAidFormLink: $financialAidFormLink,
      discordLink: $discordLink,
      regionId: $regionId,
      tenantId: $tenantId,
      judgeApplicationLink: $judgeApplicationLink,
      sponsorApplicationLink: $sponsorApplicationLink,
      volunteerApplicationLink: $volunteerApplicationLink,
      termsAndConditions: $termsAndConditions,
      fee: $fee,
    }, attachment: $attachment) {
    event {
       ${EVENT}
       discordLink
       financialAidFormLink
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent
  ($id: ID!, $title: String!, $regionId: Int!, $fee: Float!, $location: String!, $submissionDeadline: String!, $startDate: String!, $endDate: String!, $label: String!, $judgeApplicationLink: String!, $sponsorApplicationLink: String!, $volunteerApplicationLink: String!, $termsAndConditions: String!, $attachment: Upload, $financialAidFormLink: String, $discordLink: String) {
    updateEvent(input: {
      id: $id,
      title: $title,
      label: $label,
      startDate: $startDate,
      endDate: $endDate,
      submissionDeadline: $submissionDeadline,
      location: $location,
      regionId: $regionId,
      judgeApplicationLink: $judgeApplicationLink,
      sponsorApplicationLink: $sponsorApplicationLink,
      volunteerApplicationLink: $volunteerApplicationLink,
      termsAndConditions: $termsAndConditions,
      fee: $fee,
      discordLink: $discordLink
      financialAidFormLink: $financialAidFormLink
    }, attachment: $attachment) {
      event {
        id
        title
        label
        tenantId
        status
        regionId
        startDate
        endDate
        submissionDeadline
        location
        allowCoalition
        restrictToRegion
        volunteerApplicationLink
        judgeApplicationLink
        sponsorApplicationLink
        enableMinMax
        qualifiedAttendingPreviousEvent
        competitionPerCategory
        competeAdditionalCompetition
        termsAndConditions
        coverImageUrl
        fee
        registrationEarlyDeadline
        registrationFinalDeadline
        approveSchoolsCount
        unapproveSchoolsCount
        discordLink
        financialAidFormLink
      }
    }
  }
`;

export const CLONE_EVENT = gql`
  mutation cloneEvent
  ($id: ID!) {
    cloneEvent(input: {
      id: $id,
    }) {
    event {
       ${EVENT}
      }
    }
  }
`;

export const CANCEL_EVENT = gql`
  mutation cancelEvent
  ($id: ID!) {
    cancelEvent(input: {
      id: $id,
    }) {
      message
    }
  }
`;

export const CREATE_REGISTRATION_OPTION = gql`
  mutation createRegistrationOption
  ($title: String!, $fee: Float!, $minAge: Int!, $maxAge: Int!, $lateFee: Float!, $eventId: Int!, $roleId: Int!, $tenantId: Int!, $capacity: Int!, $earlyDeadline: String!, $finalDeadline: String!, $waitlistFormUrl: String!) {
    createRegistrationOption(input: {
      title: $title,
      fee: $fee,
      lateFee: $lateFee,
      eventId: $eventId,
      roleId: $roleId,
      tenantId: $tenantId,
      capacity: $capacity,
      earlyDeadline: $earlyDeadline,
      finalDeadline: $finalDeadline,
      minAge: $minAge,
      maxAge: $maxAge,
      waitlistFormUrl: $waitlistFormUrl,
    }) {
      registrationOption {
        ${REGISTRATION_OPTION}
      }
    }
  }
`;

export const UPDATE_REGISTRATION_OPTION = gql`
  mutation updateRegistrationOption
  ($id: ID!, $title: String!, $fee: Float!, $minAge: Int!, $maxAge: Int!, $lateFee: Float!, $roleId: Int!, $capacity: Int!, $earlyDeadline: String!, $finalDeadline: String!, $waitlistFormUrl: String!) {
    updateRegistrationOption(input: {
      id: $id,
      title: $title,
      fee: $fee,
      lateFee: $lateFee,
      roleId: $roleId,
      capacity: $capacity,
      earlyDeadline: $earlyDeadline,
      finalDeadline: $finalDeadline,
      minAge: $minAge,
      maxAge: $maxAge,
      waitlistFormUrl: $waitlistFormUrl,
    }) {
      registrationOption {
        ${REGISTRATION_OPTION}
      }
    }
  }
`;

export const DELETE_REGISTRATION_OPTION = gql`
  mutation deleteRegistrationOption
  ($id: Int!) {
    deleteRegistrationOption(input: {
      id: $id,
    }) {
      event {
        ${EVENT}
      }
    }
  }
`;

export const CREATE_EVENT_SETTING = gql`
  mutation createEventSetting(
    $id: ID!,
    $enableMinMax: Boolean!,
    $allowCoalition: Boolean!,
    $restrictToRegion: Boolean!,
    $competitionPerCategory: Boolean!,
    $competeAdditionalCompetition: Boolean!,
    $qualifiedAttendingPreviousEvent: Boolean!,
    $captainsCanApprove: Boolean!,
    $penalizeAbsentees: Boolean!,
    $allowsMultipleSports: Boolean!
  ){
    createEventSetting(input: {
      id: $id,
      enableMinMax: $enableMinMax,
      allowCoalition: $allowCoalition,
      restrictToRegion: $restrictToRegion,
      competitionPerCategory: $competitionPerCategory,
      competeAdditionalCompetition: $competeAdditionalCompetition,
      qualifiedAttendingPreviousEvent: $qualifiedAttendingPreviousEvent,
      captainsCanApprove: $captainsCanApprove,
      penalizeAbsentees: $penalizeAbsentees,
      allowsMultipleSports: $allowsMultipleSports
    }) {
    event {
        id
        allowCoalition
        competeAdditionalCompetition
        competitionPerCategory
        enableMinMax
        qualifiedAttendingPreviousEvent
        restrictToRegion
        captainsCanApprove
        penalizeAbsentees
        allowsMultipleSports
      }
    }
  }
`;


export const UPDATE_EVENT_COMPETITION = gql`
  mutation updateEventCompetition
  ($eventId: Int!, $tenantId: Int!, $eventCompetitions: [EventCompetitionInput!]!){
    updateEventCompetition(input: {
      eventId: $eventId,
      tenantId: $tenantId,
      eventCompetitions: $eventCompetitions,
    }) {
    eventCompetition {
       ${EVENT_COMPETITION}
      }
    }
  }
`;

export const JOIN_EVENT = gql`
  mutation joinEvent
  ($eventId: Int!, $tenantId: Int!, $userId: Int!, $roleId: Int!, $schoolId: Int, $status: Int!, $registrationOptionId: Int!, $invitationToken: String) {
    joinEvent(input: {
      eventId: $eventId,
      tenantId: $tenantId,
      userId: $userId,
      roleId: $roleId,
      schoolId: $schoolId,
      status: $status,
      registrationOptionId: $registrationOptionId,
      invitationToken: $invitationToken,

    }) {
    eventsUsers {
       ${EVENTSUSER}
      }
    result
    }
  }
`;

export const UPDATE_JOIN_EVENT = gql`
  mutation updateJoinEvent
  ($id: Int!, $parentGuardianSignature: String, $parentGuardianEmail: String, $status: Int, $registrationOptionId: Int, $signIn: Boolean ) {
    updateJoinEvent(input: {
      id: $id,
      parentGuardianSignature: $parentGuardianSignature,
      parentGuardianEmail: $parentGuardianEmail,
      status: $status,
      registrationOptionId: $registrationOptionId,
      signIn: $signIn,
    }) {
    eventsUsers {
       ${EVENTSUSER}
      }
    }
  }
`;

export const JOIN_EVENT_COMPETITION = gql`
  mutation joinEventCompetition
  ($eventsUserId: Int!, $userId: Int!, $status: Int!, $eventCompetitionId: Int!) {
    joinEventCompetition(input: {
      eventsUserId: $eventsUserId,
      userId: $userId,
      status: $status,
      eventCompetitionId: $eventCompetitionId,
    }) {
    members {
       ${MEMBER}
      }
    }
  }
`;

export const CREATE_COALITION = gql`
  mutation createCoalition
  ($eventCompetitionId: Int!, $schoolIds: [Int!]! ) {
    createCoalition(input: {
      eventCompetitionId: $eventCompetitionId,
      schoolIds: $schoolIds,
    }) {
    coalition {
       ${COALITION}
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($firstname: String!, $lastname: String!, $email: String!, $phone: String!, $gender: Int!, $dateOfBirth: String!, $accommodationNote: String, $status: String, $roleId: Int!, $regionId: Int!, $emergencyContactName: String, $emergencyContactNumber: String, $shirtSize: String, $race: String) {
    updateUser(input: {
      email: $email,
      firstname: $firstname,
      lastname: $lastname
      phone: $phone,
      gender: $gender,
      dateOfBirth: $dateOfBirth,
      accommodationNote: $accommodationNote,
      status: $status,
      roleId: $roleId,
      regionId: $regionId,
      emergencyContactName: $emergencyContactName
      emergencyContactNumber: $emergencyContactNumber
      shirtSize: $shirtSize
      race: $race
    }) {
    user {
      ${USER}
      emergencyContactName
      emergencyContactNumber
      shirtSize
      race
    }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!) {
    updatePassword(input:{
      password: $password
    }) {
    user{
      ${USER}
    }
  }
}
`;

export const UPLOAD_PROFILE_IMAGE = gql`
  mutation uploadProfileImage($profileImage: String!) {
    uploadProfileImage(input:{
      profileImage: $profileImage
    }) {
    user{
      ${USER}
    }
  }
}
`;

export const UPDATE_LINE_ITEM = gql`
  mutation updateLineItem
  ($id: Int!, $lineItemAbleId: Int, $amount: Int ) {
    updateLineItem(input: {
      id: $id,
      lineItemAbleId: $lineItemAbleId,
      amount: $amount,
    }) {
    lineItem {
       ${LINE_ITEM}
      }
    order{
       ${ORDER}
      }
    }
  }
`;

export const UPDATE_ORDER_MERCH = gql`
  mutation updateOrderMerch($id: Int!, $merchandiseIds: [Int!]!) {
    updateOrderMerch(input: {
      id: $id,
      merchandiseIds: $merchandiseIds
    }) {
      order {
        ${ORDER}
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder
  ($id: Int!, $mistFee: Float, $stripeFee: Float, $couponDiscount: Float, $amount: Int, $status: Int, $transactionId: String ) {
    updateOrder(input: {
      id: $id
      mistFee: $mistFee,
      stripeFee: $stripeFee,
      couponDiscount: $couponDiscount,
      amount: $amount,
      status: $status,
      transactionId: $transactionId,
    }) {
      order{
        ${ORDER}
      }
    }
  }
`;

export const CHECK_COUPON_VALIDITY = gql`
  mutation checkCouponValidity
  ($orderId: Int!, $couponCode: String, $registrationOptionId: Int!) {
    checkCouponValidity(input: {
      orderId: $orderId,
      registrationOptionId: $registrationOptionId,
      couponCode: $couponCode,
    }) {
      coupon{
        ${COUPON}
      }
      lineItem{
        ${LINE_ITEM}
      }
      order{
        ${ORDER}
      }
      message
    }
  }
`;


export const DENY_EVENT_USER = gql`
  mutation denyEventUser($id: ID!, $status: Int!, $comment: String!) {
    denyEventUser(input:{
      id: $id,
      status: $status,
      comment: $comment
    }) {
    eventsUsers{
      id
    }
  }
}
`;

export const APPROVED_EVENT_USER_BY_ADMIN = gql`
  mutation approveCaptainByAdmin($id: ID!, $status: Int!) {
    approveCaptainByAdmin(input:{
      id: $id,
      status: $status
    }) {
    eventsUsers{
      ${EVENT_USER}
    }
  }
}
`;

export const APPROVED_EVENT_USER_BY_CAPTAIN = gql`
  mutation approveUserByCaptain($id: ID!, $status: Int!) {
    approveUserByCaptain(input:{
      id: $id,
      status: $status
    }) {
    eventsUsers{
      ${EVENT_USER}
    }
  }
}
`;

export const PENDING_EVENT_USER = gql`
  mutation pendingEventUser($id: ID!, $status: Int!, $comment: String!, $schoolId: Int!) {
    pendingEventUser(input:{
      id: $id,
      status: $status,
      comment: $comment,
      schoolId: $schoolId,
    }) {
    eventsUsers{
      ${EVENT_USER}
    }
  }
}
`;

export const UPDATE_SCHOOL_VERIFICATION = gql`
  mutation schoolUserAttachment
  ($schoolId: Int!, $userId: Int!, $verificationFile: String) {
    schoolUserAttachment(input: {
      schoolId: $schoolId
      userId: $userId,
      verificationFile: $verificationFile
    }) {
    schoolsUsers{
      ${SCHOOL_USER}
      }
    }
  }
`;

export const CREATE_CHARGE = gql`
  mutation createCharge($orderId: Int!, $email: String!, $token: String!, $clientIp: String!, $userFullName: String!) {
    createCharge(input:{
      orderId: $orderId,
      email: $email,
      token: $token,
      clientIp: $clientIp,
      userFullName: $userFullName,
    }) {
      order{
        ${ORDER}
      }
      message
  }
}
`;
export const CANCEL_REGISTRATION_OPTION = gql`
  mutation cancelRegistrationOption
  ($eventsUserId: ID!, $status: Int) {
    cancelRegistrationOption(input: {
      eventsUserId: $eventsUserId,
      status: $status,
    }) {
      eventsUser{
        ${EVENT_USER}
      }
    }
  }
`;

export const INVITE_EXITING_USER = gql`
  mutation invitation($invitableId: Int!, $invitableType: String!, $invitations: [UserInvitationInput!]!) {
    invitation(input:{
      invitableId: $invitableId,
      invitableType: $invitableType,
      invitations: $invitations,
    }) {
      result
    }
  }
`;

export const REASSIGN_CAPTAIN = gql`
  mutation reassignCaptain
  ($schoolId: Int!, $eventId: Int!, $eventsUserId: Int!) {
    reassignCaptain(input: {
      schoolId: $schoolId
      eventId: $eventId,
      eventsUserId: $eventsUserId
    }) {
      eventsUser{
        id 
        roleId
        userId
        schoolId
        eventId
        status
        signIn
        createdAt
        parentGuardianSignature
        parentGuardianEmail
        teamFinalize
        fetchUser{
          id
          email
          status
          phone
          userType
          gender
          tenantId
          dateOfBirth
          firstname
          lastname
        }
      }
    }
  }
`;

export const REMOVE_MEMBER = gql`
  mutation removeMember($memberId: Int!, $status: Int) {
    removeMember(input:{
      memberId: $memberId,
      status: $status,
    }) {
    user{
      ${USER}
      }
    }
  }
`;


export const HELP = gql`
  mutation help($email: String!, $subject: String!, $message: String!){
    help(input:{
      email: $email,
      subject: $subject,
      message: $message,
    }) {
      result
    }
  }
`;

export const WAITLIST_SWAP = gql`
  mutation waitlistSwap
  ($eventCompetitions: [MemberEventCompetitionInput!]!, $eventId: Int!,$schoolId: Int!){
    waitlistSwap(input: {
      eventCompetitions: $eventCompetitions,
    }) {
      competitions{
        id
        status
        title
        tenantId
        fetchCategory{
          id
          title
          tenantId
        }
        fetchEventCompetition(eventId: $eventId){
          id
          title
          tenantId
        }
        fetchMembers(eventId:$eventId,schoolId: $schoolId){
          id
          userId
          eventsUserId
          eventCompetitionId
          status
          reason
          competitionPriority
          memberPriority
          fetchUser{
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`;

export const MEMBER_SWAP = gql`
  mutation memberSwap
  ($members: [MemberInput!]!){
    memberSwap(input: {
      members: $members,
    }) {
      members{
        ${MEMBER}
      }
    }
  }
`;

export const REMOVE_SCHOOL_VERIFICATION = gql`
  mutation deleteSchoolUserAttachment
  ($id: ID!) {
    deleteSchoolUserAttachment(input: {
      id: $id,
    }) {
    schoolsUsers{
      ${SCHOOL_USER}
      }
    }
  }
`;

export const CHANGE_REGISTRATION_OPTION = gql`
  mutation changeRegistrationOption
  ($eventsUserId: ID!) {
    changeRegistrationOption(input: {
      eventsUserId: $eventsUserId,
    }) {
      eventsUser{
        ${EVENT_USER}
      }
    }
  }
`;

export const PARENT_GUARDIAN_SIGNATURE = gql`
  mutation parentGuardianSignature
  ($id: Int!,$parentGuardianSignature: String!) {
    parentGuardianSignature(input: {
    id: $id, parentGuardianSignature: $parentGuardianSignature
  }){
    eventsUsers {
      id
      eventId
      tenantId
      userId
      roleId
      parentGuardianSignature
      status
      signIn
    }
  }
}
`;

export const CHANGE_SCHOOL = gql`
  mutation changeUserSchool
  ($currentSchoolId: Int!, $userId: Int!, $newSchoolId: Int!, $verificationFile: String) {
    changeUserSchool(input: {
      currentSchoolId: $currentSchoolId
      userId: $userId,
      newSchoolId: $newSchoolId
      verificationFile: $verificationFile
    }) {
    schoolsUsers{
      ${SCHOOL_USER}
      }
    }
  }
`;

export const TEAM_FINALIZE = gql`
  mutation teamFinalize
  ($eventId: Int!, $schoolId: Int!, $status: Int!) {
    teamFinalize(input: {
      eventId: $eventId,
      schoolId: $schoolId,
      status: $status,
    }) {
    finalizeTeam{
      ${FINALIZE_TEAM}
      }
    }
  }
`;

export const TEAM_UNFINALIZE = gql`
  mutation unfinalizeTeam
  ($eventId: Int!, $schoolId: Int!, $status: Int!) {
    unfinalizeTeam(input: {
      eventId: $eventId,
      schoolId: $schoolId,
      status: $status,
    }) {
      result
    }
  }
`;

export const REMOVE_INVITED_USER = gql`
  mutation removeInvitation($invitableId: Int!, $invitableType: String!, $userId: Int!) {
    removeInvitation(input:{
      invitableId: $invitableId,
      invitableType: $invitableType,
      userId: $userId,
    }) {
    user{
      ${USER}
      }
    }
  }
`;

export const JOIN_EVENT_BY_INVITATION = gql`
  mutation joinEventByInvitation
  ($invitationCode: String!) {
    joinEventByInvitation(input: {
      invitationCode: $invitationCode
    }){
      eventsUser {
       ${EVENT_USER}
      }
    result
    }
  }
`;

export const REMOVE_EVENT_USER = gql`
  mutation removeEventUser($eventUserId: Int!, $status: Int) {
    removeEventUser(input:{
      eventUserId: $eventUserId,
      status: $status,
    }) {
    user{
      ${USER}
      }
    }
  }
`;

export const COACH_ANOTHER_SCHOOL = gql`
  mutation coachAnotherSchool
  ($eventId: Int!, $schoolId: Int) {
    coachAnotherSchool(input: {
      eventId: $eventId,
      schoolId: $schoolId,

    }) {
    result
    }
  }
`;

export const CREATE_SCHOOL_USER = gql`
  mutation createSchoolUser
  ($schoolId: Int!, $verificationFile: String) {
    createSchoolUser(input: {
      schoolId: $schoolId
      verificationFile: $verificationFile
    }) {
    schoolsUsers{
      ${SCHOOL_USER}
      }
    }
  }
`;

export const REMOVE_SCHOOL_USER = gql`
  mutation deleteSchoolUser
  ($id: ID!) {
    deleteSchoolUser(input: {
      id: $id
    }) {
      message
    }
  }
`;

export const UPDATE_DEFAULT_SCHOOL = gql`
  mutation updateDefaultSchool
  ($schoolId: Int!) {
    updateDefaultSchool(input: {
      schoolId: $schoolId
    }) {
    schoolsUsers{
      ${SCHOOL_USER}
      }
    }
  }
`;

export const FINALIZED_ROUND = gql`
  mutation finalizedRound
  ($input: FinalizedRoundInput!) {
    finalizedRound(input: $input) {
      round{
        id
        status
        tenantId
        eventCompetitionId
        name
        numberMatchups
        judgables {
          id
          type
          code
          totalScore
          members {
            id
            code
          }
          penalty {
            attendance
          }
        }
      }
    }
  }
`;

export const UNFINALIZED_ROUND = gql`
  mutation unfinalizedRound
  ($roundId: ID!) {
    unfinalizedRound(input:{
      roundId: $roundId
    }) {
      round{
        id
        status
        tenantId
        eventCompetitionId
        name
        numberMatchups
        judgables {
          id
          type
          code
          totalScore
          members {
            id
            code
          }
          penalty {
            attendance
          }
        }
      }
    }
  }
`;

export const CREATE_ROUND = gql`
  mutation createRound($eventCompetitionId: ID!) {
    createRound(input: { eventCompetitionId: $eventCompetitionId }) {
      round {
        id
        status
        tenantId
        eventCompetitionId
        name
        numberMatchups
      }
    }
  }
`;

export const WITHDRAW_TEAM = gql`
  mutation withdrawCoachFromTeam
  ($eventId: Int!, $schoolId: Int!) {
    withdrawCoachFromTeam(input: {
      eventId: $eventId,
      schoolId: $schoolId,
    }) {
      message
    }
  }
`;

export const UPLOAD_COMPETITION_SUBMISSION = gql`
  mutation uploadCompetitionSubmission ($file: Upload, $externalLink: String, $memberId: ID!) {
    uploadCompetitionSubmission(attachment: $file, externalLink: $externalLink, memberId: $memberId) {
      result
      savedAttachment {
        id
        memberId
        attachmentUrl
      }
    }
  }
`;

export const CREATE_RESOURCE = gql`
  mutation createResource(
      $eventId: Int!,
      $title: String!,
      $type: String!,
      $body: String,
      $attachment: Upload
    ) {
      createResource(
        body: $body,
        eventId: $eventId,
        title: $title,
        type: $type,
        attachment: $attachment
      ) {
        resource {
          id
          eventId
          title
          type
          body
          attachmentUrl
          attachmentName
      }
    }
  }
`;

export const DELETE_RESOURCE = gql`
  mutation deleteResource($id: Int!) {
    deleteResource(input: { id: $id }) {
      status
    }
  }
`;

export const CREATE_SCHEDULE_EVENT = gql`
  mutation createScheduleEvent(
      $input: CreateScheduleEventInput!
    ) {
      createScheduleEvent(input: $input) {
        scheduleEvent {
          id
          title
          type
          description
          startTime
          endTime
          locationId
          fetchLocation {
            id
            name
          }
          room
          associatedEventCompetitionId
          fetchAssociatedEventCompetition {
            id
            title
          }
        }
      }
    }
`;

export const UPDATE_SCHEDULE_EVENT = gql`
  mutation updateScheduleEvent(
      $input: UpdateScheduleEventInput!
    ) {
      updateScheduleEvent(input: $input) {
        scheduleEvent {
          id
          title
          type
          description
          startTime
          endTime
          locationId
          fetchLocation {
            id
            name
          }
          room
          associatedEventCompetitionId
          fetchAssociatedEventCompetition {
            id
            title
          }
        }
      }
    }
`;

export const DELETE_SCHEDULE_EVENT = gql`
  mutation deleteScheduleEvent($input: DeleteScheduleEventInput!) {
    deleteScheduleEvent(input: $input) {
      status
    }
  }
`;

export const ADD_USER_TO_SUBTEAM = gql`
  mutation addUserToCoalitionSubteam
  ($coalitionId: ID!, $userId: ID!) {
    addUserToCoalitionSubteam(input: {
      coalitionId: $coalitionId,
      userId: $userId
    }) {
      coalitionMembers {
        id
        user {
          id
        }
      }
    }
  }
`;

export const REMOVE_USER_FROM_SUBTEAM = gql`
  mutation removeUserFromCoalitionSubteam
  ($coalitionId: ID!, $userId: ID!) {
    removeUserFromCoalitionSubteam(input: {
      coalitionId: $coalitionId,
      userId: $userId
    }) {
      coalitionMembers {
        id
        user {
          id
        }
      }
    }
  }
`;

export const SET_BALLOT = gql`
  mutation setBallot($input: SetBallotInput!) {
    setBallot(input: $input) {
      ballot {
        id
        title
        totalPoints
        fetchBallotSections {
          id
          title
          description
          totalPoints
        }
      }
    }
  }
`;

export const ASSIGN_JUDGES = gql`
  mutation AssignJudges($input: AssignJudgesInput!) {
    assignJudges(input: $input) {
      result
    }
  }
`;

export const RESEND_JUDGE_INVITE = gql`
  mutation ResendJudgeInvite($input: ResendJudgeInviteInput!) {
    resendJudgeInvite(input: $input) {
      result
    }
  }
`;

export const SUBMIT_SCORE = gql`
  mutation submitScore($input: SubmitScoreInput!) {
    submitScore(input: $input) {
      ballotScore {
        id
        totalScore
        sections {
          id
          points
          ballotSection { id }
        }
        comments
        privateNotes
      }
    }
  }
`;

export const DELETE_SCORE = gql`
mutation deleteScore($input: DeleteScoreInput!) {
  deleteScore(input: $input) {
    message
  }
}
`;

export const SUBMIT_PENALTY = gql`
  mutation submitPenalties($input: SubmitPenaltiesInput!) {
    submitPenalties(input: $input) {
      round{
        id 
        status
        tenantId
        eventCompetitionId
        name
        numberMatchups
      }
    }
  }
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation createAnnouncement(
      $input: CreateAnnouncementInput!,
    ) {
      createAnnouncement(
        input: $input
      ) {
        announcement {
          id
          eventId
          title
          body
          link
          targetType
          targetId
          isImportant
          createdAt
          fetchSchools {
            id
            name
          }
          fetchCompetitions {
            id
            title
          }
          fetchRoles {
            id
            title
          }
      }
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($announcementId: Int!) {
    deleteAnnouncement(input: { announcementId: $announcementId }) {
      status
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation createLocation(
      $input: CreateLocationInput!,
    ) {
      createLocation(
        input: $input
      ) {
        location {
          name
          longitude
          latitude
          eventId
      }
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($locationId: Int!) {
    deleteLocation(input: { locationId: $locationId }) {
      status
    }
  }
`;
