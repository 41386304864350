import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr, isEmpty } from 'lodash/fp';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import { FETCH_EVENT_USERS_BY_EVENT, FETCH_EVENT } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import ListItemDetail from '../../Components/ListItemDetail/Index';
import Search from '../../Components/Search/Index';
import userDefaultImage from '../../Images/icons/userIcon.png';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import useUpdateEventsUsersCache from '../../Hooks/useUpdateEventsUsersCache';

const CaptainRequest = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const [filteredEventUsers, setFilteredEventUsers] = useState([]);
  const [totalRequests, setTotalRequests] = useState([]);
  const { eventId, id } = useParams();
  const updateCache = useUpdateEventsUsersCache(parseInt(eventId, 10), parseInt(id, 10));
  const [fetchEventUsersByEvent, { loading, error }] = useLazyQuery(
    FETCH_EVENT_USERS_BY_EVENT, {
      onCompleted: (data) => {
        updateCache(data.fetchEventUsersByEvent);
        setFilteredEventUsers(data.fetchEventUsersByEvent);
        setTotalRequests(data.fetchEventUsersByEvent);
      },
    },
  );
  const extractError = useGraphQLErrorExtractor();

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useEffect(() => {
    if (currentUser) {
      fetchEventUsersByEvent({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventUsersByEvent, eventId, id]);


  useHeaderComponentsMutation({
    backLink: `/event-manage/${eventId}/unapprove-teams`,
    title: 'TEAM CAPTAINS',
    components: [{ key: EVENT_TITLE, value: event.title }],
  });


  const searchEventsUser = (e) => {
    if (!isEmpty(e.target.value)) {
      setFilteredEventUsers(
        filteredEventUsers.filter(
          (x) => ((`${x.fetchUser.firstname} ${x.fetchUser.lastname}`).toLowerCase()).includes(e.target.value),
        ),
      );
    } else {
      setFilteredEventUsers(totalRequests);
    }
  };


  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <Search
        placeholder="Search"
        onChange={(e) => searchEventsUser(e)}
      />
      {filteredEventUsers.length === 0
        ? <p>No Record Found</p>
        : null}
      <div className="row">
        <div className="col-12 mx-auto px-4">
          {totalRequests.length > 0
            ? (
              <FontBold>
                <p className="mt-4">
                Pending Requests (
                  {filteredEventUsers.length}
                )
                </p>
              </FontBold>
            )
            : null}
        </div>
      </div>
      <ListItemDetail data={filteredEventUsers} imageAttached={userDefaultImage} redirectLink={`/event-manage/${eventId}/team-captains`} userCode="true" dataType="events" subLink="team-conversations" />
      <br />
    </>
  );
};
export default CaptainRequest;
