import React, { useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Input,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FaSearch, FaChevronDown } from 'react-icons/fa';

import userLookupIcon from '../../../Images/event-manage-v2/user-lookup.png';

import { Card } from './Card';
import { CompetitionsModal } from './EditCompetitionsModal';

const FETCH_EVENTS_USERS = gql`
query fetchEventsUsersByUser($eventId: Int!, $code: String, $email: String) {
  fetchUserDetail(code: $code, email: $email) {
    firstname
    lastname
    code
    profileImageUrl
    dateOfBirth
    gender
    id
  }
  fetchEventsUsersByUser(eventId: $eventId, code: $code, email: $email) {
    id
    status
    schoolId
    fetchTeam {
      id
      code
      school {
        id
        name
      }
    }
    fetchSchoolUser {
      status
      schoolId
      fetchUserAttachment {
        id
        verificationFileUrl
      }
    }
    fetchRole {
      id
      title
    }
    fetchMembers {
      id
      fetchEventCompetition {
        id
        title
        status
      }
    }
    fetchOrder {
      id
      amount
      status
    }
  }
}
`;

export const QuickUserLookup = ({ eventId }) => {
  const [input, setInput] = useState('');
  const toast = useToast();
  const [fetchEventsUsers, { data, loading }] = useLazyQuery(FETCH_EVENTS_USERS, {
    onCompleted: () => {},
    onError: (err) => {
      toast({
        title: err.graphQLErrors[0]?.message ?? 'An unknown error occurred. Please try again later.',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    },
  });

  const lookupUser = useCallback(async (evt, input) => {
    evt.preventDefault();
    const variables = { eventId };
    if (input.includes('@')) {
      // it's an email
      variables.email = input;
    } else {
      variables.code = input;
    }

    fetchEventsUsers({ variables });
  }, [fetchEventsUsers, eventId]);

  return (
    <Card title="Quick User Lookup" imgSrc={userLookupIcon}>
      <Box width="100%">
        <form onSubmit={(e) => lookupUser(e, input)} width="100%">
          <HStack width="100%">
            <Input placeholder="Enter MIST ID or email address" value={input} onChange={(e) => setInput(e.target.value)} />
            <IconButton icon={<FaSearch />} aria-label="search" type="submit" isLoading={loading} />
          </HStack>
        </form>
        {!!data && (
          <UserInfo
            eventId={eventId}
            user={data.fetchUserDetail}
            eventsUsers={data.fetchEventsUsersByUser}
          />
        )}
      </Box>
    </Card>
  );
};

const SchoolUserStatus = ({ status }) => {
  switch (status) {
    case 'active': return <Text as="span" color="blue.500" fontWeight="bold">Approved</Text>;
    case 'pending': return <Text as="span" color="orange.500">Pending</Text>;
    default: return <Text>{status}</Text>;
  }
};

const SchoolVerificationModal = ({ isOpen, onClose, verificationFileUrl }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>School Verification</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Image src={verificationFileUrl} />
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const SchoolInfo = ({ code, status, role, team, verificationAttachments, isCaptain }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visibleVerificationFileUrl, setVisibleVerificationFileUrl] = useState();
  const showVerification = (verificationFileUrl) => {
    setVisibleVerificationFileUrl(verificationFileUrl);
    onOpen();
  };

  return (
    <Box borderStartColor="grey.200" borderStartWidth={2} paddingInlineStart={2}>
      <Text color="gray.500">{code}</Text>
      <Text>
        {team.school.name}
        {isCaptain ? ' (Captain)' : ''}
      </Text>
      <Box>
        <Box marginBlockStart={0}>
          <SchoolUserStatus status={status} />
          {' '}
          {role.title}
        </Box>
        {verificationAttachments.map((attachment) => (
          <Button
            key={attachment.id}
            variant="link"
            colorScheme="blue"
            fontWeight="light"
            onClick={() => showVerification(attachment.verificationFileUrl)}
          >
            View School Verification
          </Button>
        ))}
      </Box>
      <SchoolVerificationModal
        isOpen={isOpen}
        onClose={onClose}
        verificationFileUrl={visibleVerificationFileUrl}
      />
    </Box>
  );
};

const PaymentStatus = ({ status }) => {
  switch (status) {
    case 'complete': return <Text as="span" color="green.500" fontWeight="bold">Paid</Text>;
    case 'pending': return <Text as="span" color="orange.500">Pending</Text>;
    default: return <Text>{status}</Text>;
  }
};

const PaymentInfo = ({ order }) => {
  const amount = order.amount / 100;
  return (
    <Box>
      <PaymentStatus status={order.status} />
      {' amount of $'}
      {amount}
    </Box>
  );
};

const CompetitionsInfo = ({ eventId, userId, eventUserId, members }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant="link"
        colorScheme="blue"
        fontWeight="light"
        onClick={() => onOpen()}
      >
        View/Edit Competitions
      </Button>
      <CompetitionsModal
        isOpen={isOpen}
        onClose={onClose}
        eventId={eventId}
        userId={userId}
        eventUserId={eventUserId}
        members={members}
      />
    </>
  );
};

const EventUserInfo = ({ eventId, user, eventUser, isCaptain }) => {
  let { code } = user;
  if (eventUser.fetchTeam) {
    code = `${eventUser.fetchTeam.code}-${user.code}`;
  }
  console.log(eventUser)
  console.log(user)

  return (
    <>
      <SchoolInfo
        code={code}
        status={eventUser.status}
        team={eventUser.fetchTeam}
        role={eventUser.fetchRole}
        verificationAttachments={eventUser.fetchSchoolUser.fetchUserAttachment}
        isCaptain={isCaptain}
      />
      <PaymentInfo order={eventUser.fetchOrder} />
      {eventUser.fetchMembers && (
        <CompetitionsInfo
          eventId={eventId}
          userId={user.id}
          eventUserId={eventUser.id}
          members={eventUser.fetchMembers}
        />
      )}
    </>
  );
};

const CAPTAIN_ROLE_ID = '6';
const UserInfo = ({ eventId, user, eventsUsers }) => {
  const fullname = `${user.firstname} ${user.lastname}`;

  const isCaptainForSchools = eventsUsers
    .filter((eu) => eu.fetchRole.id === CAPTAIN_ROLE_ID)
    .map((eu) => eu.schoolId);

  const nonCaptainEventUsers = eventsUsers
    .filter((eu) => eu.fetchRole.id !== CAPTAIN_ROLE_ID);

  return (
    <HStack alignItems="start" width="100%" marginBlockStart={4}>
      <Image height={12} width={12} borderRadius="100%" src={user.profileImageUrl} />
      <VStack alignItems="start">
        <Text>{fullname}</Text>
        {nonCaptainEventUsers.map((eu) => (
          <EventUserInfo
            key={eu.id}
            eventId={eventId}
            user={user}
            eventUser={eu}
            isCaptain={isCaptainForSchools.includes(eu.schoolId)}
          />
        ))}
      </VStack>
      <HStack flexGrow={1} justifyContent="end">
        <Menu>
          <MenuButton as={Button} colorScheme="gray" rightIcon={<FaChevronDown />}>
            Edit Details
          </MenuButton>
          <MenuList>
            <MenuItem isDisabled>All options coming soon!</MenuItem>
            {eventsUsers.length === 1 && (
              <>
                {eventsUsers[0].status === 'pending' && (
                  <>
                    <MenuItem isDisabled>Approve Registration</MenuItem>
                    <MenuDivider />
                  </>
                )}
                <MenuItem isDisabled>Promote to Captain</MenuItem>
                <MenuItem isDisabled>Change School</MenuItem>
                <MenuItem isDisabled>Change Registration Type</MenuItem>
                <MenuDivider />
              </>
            )}
            <MenuItem isDisabled>Mark As Paid</MenuItem>
            <MenuItem isDisabled>Apply Coupon</MenuItem>
            <MenuDivider />
            <MenuItem isDisabled color="red.600">Cancel Registration</MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </HStack>
  );
};
