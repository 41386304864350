import React from 'react';
import PropTypes from 'prop-types';
import {
  HStack,
  Heading,
  Image,
  VStack,
} from '@chakra-ui/react';

export const Card = ({ imgSrc, title, children }) => (
  <VStack
    width="100%"
    align="start"
    borderColor="gray.200"
    borderWidth={2}
    borderRadius={4}
    gap={2}
    padding={6}
  >
    <HStack gap={2}>
      <Image
        width="48px"
        height="48px"
        borderRadius="10px"
        src={imgSrc}
      />
      <Heading as="h2" size="sm" color="#3E6796" textTransform="uppercase">
        {title}
      </Heading>
    </HStack>
    {children}
  </VStack>
);
Card.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
