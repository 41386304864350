import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  isEmpty, get, getOr, last,
} from 'lodash/fp';
import { Button, Row, Col } from 'reactstrap';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { SIGN_IN } from '../../GraphQL/Mutations';
import { TitleC } from '../../Components/Auth/Layout';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useUserSession from '../../Hooks/useUserSession';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import { FETCH_INVITATION_DETAIL_BY_CODE } from '../../GraphQL/Queries';
import Form from '../../Components/Form';
import { INVITATION_CODE } from '../../Constants/AppConstants';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';

const NewUserSignUpButton = styled(Button)`
  width: 100%;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  font-size: 14px !important;
  box-shadow: none !important;
  min-height: 45px !important;
  text-shadow: none !important;
  font-weight: bold !important;
  font-family: 'nunito_bold' !important;
  border: 2px solid #f3AA44 !important;
  color: rgba(244, 171, 55, 1) !important;
  background: rgba(255, 255, 255, 1) !important;
  border-radius: 10px !important;
`;

const ForgotPassword = styled.div`
  padding-top: 13px;
  padding-bottom: 16px;
  a {
    font-size: 12pt;
    font-weight: 600;
    line-height: 19px;
    color: rgba(148, 148, 148, 1);
    font-family: 'nunito_extrabold';  
}
`;

const DividerLine = styled.div`
  line-height: 0.5;
  text-align: center;
  overflow: hidden;
  span {
    display: inline-block;
    position: relative;
    border: 1px solid rgba(151, 151, 151, .9);
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 100%;
    font-family: 'nunito_regular';
    ::before {
    right: 100%;
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(151, 151, 151, .5);
    width: 600px;
    top: 50%;
    transform: translateY(-50%);
  }
  ::after {
    left: 100%;
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(151, 151, 151, .5);
    width: 600px;
    top: 50%;
    transform: translateY(-50%);
    }
  }
`;


const Or = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  font-family: 'nunito_regular';
  color: rgba(151, 151, 151, 1);
`;

const SignIn = () => {
  const history = useHistory();
  const { saveUserSession } = useUserSession();
  const { currentUser } = useCurrentUser();
  const [errorMessage, seterrorMessage] = useState('');
  const extractError = useGraphQLErrorExtractor();
  const [cookies] = useCookies([INVITATION_CODE]);
  const checkPermission = useUserPermissionChecker(currentUser);
  useHeaderComponentsMutation({ title: 'Log in / Sign Up' });
  const [signIn, { loading, error, client }] = useMutation(SIGN_IN, {
    onCompleted: (data) => {
      if (data.login.user.confirmedAt) {
        saveUserSession(data.login.token);
        client.resetStore();
      } else {
        seterrorMessage('Please check email to confirm account.');
      }
    },
    onError: () => {},
  });
  const { params, onChange } = useFormParams({
    email: '',
    password: '',
  });

  const [fetchInvitationDetail, { data: dataInvitation }] = useLazyQuery(
    FETCH_INVITATION_DETAIL_BY_CODE,
  );
  const invitation = getOr([], 'fetchInvitationDetail', dataInvitation);

  useEffect(() => {
    if (currentUser && (!cookies.invitationCode)) {
      if (currentUser.status !== 'active') {
        history.replace('/me/edit-profile');
      } else if (checkPermission(Permissions.CAN_JOIN_SCHOOL)
                 && (isEmpty(get('fetchSchoolsUsers', currentUser))
                 || isEmpty(get('fetchUserAttachment', last(get('fetchSchoolsUsers', currentUser)))))) {
        history.replace('/school-verification');
      } else if (!isEmpty(currentUser.fetchEventsUsers)) {
        history.replace('/dashboard');
      } else {
        history.replace('/');
      }
    } else if (currentUser && !isEmpty(invitation)) {
      if (currentUser.status === 'active' && cookies.invitationCode && invitation.registrationOptionId) {
        history.push(`/join-event/${cookies.invitedEventId}?invitation_code=${cookies.invitationCode}`);
      } else if (currentUser.status === 'active' && cookies.invitationCode) {
        history.push(`/event-manage/${cookies.invitedEventId}?invitation_code=${cookies.invitationCode}`);
      } else {
        history.replace('/');
      }
    }
  }, [checkPermission, cookies.invitationCode, cookies.invitedEventId,
    currentUser, history, invitation]);

  useEffect(() => {
    if (currentUser && cookies.invitationCode) {
      fetchInvitationDetail({
        variables: {
          invitationCode: cookies.invitationCode,
        },
      });
    }
  }, [cookies.invitationCode, currentUser, fetchInvitationDetail, invitation]);

  const onSubmit = (e) => {
    e.preventDefault();
    signIn({ variables: params });
  };

  return (
    <>
      <Row className="mt-3"> 
        <Col lg={{size: 6, offset: 3}}> 
          {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
          { errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
          <Form onSubmit={onSubmit}>
            <TitleC>
              <p className="mt-4">Log into MIST</p>
            </TitleC>
            <Input
              title="Email"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              onChange={onChange}
              withFormGroup
              label="email"
              labelClass="sr-only"
              params={params}
            />
            <Input
              title="Passphrase"
              type="password"
              id="pwd"
              name="password"
              label="password"
              placeholder="Passphrase"
              minLength="8"
              required
              onChange={onChange}
              withFormGroup
              labelClass="sr-only"
              params={params}
            />
            <InputButton loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">log in</InputButton>
            <ForgotPassword>
              <Link to="/password/reset">Forgot Passphrase </Link>
            </ForgotPassword>
          </Form>
          <DividerLine>
            <Or>or</Or>
          </DividerLine>
          <div className="py-5">
            <Link to="/sign_up">
              <NewUserSignUpButton className="btn btn__transparent--secondary">new user sign up</NewUserSignUpButton>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default SignIn;
