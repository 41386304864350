import React from 'react';
import EventManageV2 from './EventManageV2';
import EventManage from './EventManage';

function shouldShowNewEventPage() {
  return Boolean(window.localStorage.getItem('useNewEventPage'));
}

export default function Event() {
  if (shouldShowNewEventPage()) {
    return <EventManageV2 />;
  }
  return <EventManage />;
}
