import React from 'react';
import { Provider } from '../../../Chakra';
import { ManageEvent } from './ManageEvent';


export default () => (
  <Provider>
    <ManageEvent />
  </Provider>
);
